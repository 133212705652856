import { theme } from '@chakra-ui/react'

const ourTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    nrr: {
      blue: {
        main: '#0087D8',
        50: '#d9f7ff',
        100: '#ace3ff',
        200: '#7bcfff',
        300: '#49bcff',
        400: '#0087D8', // main
        500: '#008fe6',
        600: '#0070b4',
        700: '#005082',
        800: '#003051',
        900: '#001121',
      },
      yellow: {
        main: '#FFD846',
        50: '#fffadb',
        100: '#ffefaf',
        200: '#ffe47e',
        300: '#FFD846',
        400: '#FFD846',
        500: '#FFD846',
        600: '#ffcf1e',
        700: '#e6b507',
        800: '#b38d00',
        900: '#806500',
      },
      black: '#434A54',
      textGray: '#9B9B9B',
      backgroundGray: '#EFEFEF',
      gray: {
        100: '#dcd8d9',
        200: '#bfbfbf', // dark
        300: '#a6a6a6',
        400: '#8c8c8c',
        500: '#737373',
        600: '#595959',
        700: '#404040',
        800: '#282626',
        900: '#150a0d',
      },
      white: {
        main: '#FFFFFF',
        100: '#FFFFFF',
        200: '#FFFFFF',
        300: '#FFFFFF',
        400: '#FFFFFF',
        500: '#FFFFFF',
        600: '#EFEFEF', // dark
        700: '#EFEFEF',
        800: '#EFEFEF',
        900: '#EFEFEF',
      },
      negativeRed: '#bc1c1c',
      positiveGreen: '#4EAA38',
    },
  },
  shadows: {
    ...theme.shadows,
    nrr: {
      light: '0 1px 1px 0 rgba(0, 0, 0, 0.3)',
      dark: '0 1px 1px 0 rgba(67, 74, 84, 0.3)',
    },
  },
  fonts: {
    body: 'Source Sans Pro, sans-serif',
    heading: 'Source Sans Pro, sans-serif',
    mono: theme.fonts.mono,
  },
  fontSizes: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '20px',
    '3xl': '24px',
    '4xl': '32px',
    '5xl': '44px',
    '6xl': '60px',
  },
  columnStyle: {
    backgroundColor: 'nrr.backgroundGray',
    borderBottom: 'solid 1px',
    borderBottomColor: 'nrr.textGray',
    paddingX: '4px',
    height: '100%',
    fontSize: ['sm', 'sm', 'md', 'lg'],
  },
  rowStyle: {
    borderBottom: 'solid 1px',
    borderBottomColor: 'nrr.backgroundGray',
    paddingY: '14px',
  },
  cellStyle: {
    paddingX: ['2px', '4px'],
    fontSize: ['sm', 'sm', 'md', 'lg'],
  },
  cellInnerStyle: {
    width: '100%',
  },
  formElementCoreStyle: {
    height: '40px',
    fontSize: ['sm', 'sm', 'md'],
  },
  formElementCoreStyleSmall: {
    height: '34px',
  },
  headingStyle: {
    marginY: '7px',
  },
  competitions: [
    {
      id: 'cea0b2e7-985c-47d7-8539-b3c53882b761',
      name: 'Testing Comp (TEST)',
      enviro: 'TEST',
      active: true,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#10044a',
      primaryDarkColor: '#070221',
      headingTextColor: '#FFFFFF',
    },
    {
      id: '5ed9919e-0baf-47d0-bc27-d7bc63ce7a50',
      name: 'Testing Comp (UAT)',
      enviro: 'UAT',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#42a442',
      primaryDarkColor: '#348034',
      headingTextColor: '#FFFFFF',
    },
    {
      id: '9bc1211e-1116-4e5f-95bf-75242b1f6e0a',
      name: "The Men's 6ixty (UAT)",
      enviro: 'UAT',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#040130',
      primaryDarkColor: '#010007',
      headingTextColor: '#FFFFFF',
    },
    {
      id: '36bd4239-fea9-4f45-8960-2ed8b05bb9c9',
      name: "The Women's 6ixty (UAT)",
      enviro: 'UAT',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#aef801',
      primaryDarkColor: '#95d107',
      headingTextColor: '#040130',
    },
    {
      id: 'a1b492b0-6eb5-434a-a84c-f4d3a9b683b1',
      name: "Men's CPL T20 (UAT)",
      enviro: 'UAT',
      active: true,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#1b1d41',
      primaryDarkColor: '#0b0c24',
      headingTextColor: '#FFFFFF',
    },
    {
      id: '5d761f85-c39c-4f79-873f-127a78376ab1',
      name: "Women's CPL T20 (UAT)",
      enviro: 'UAT',
      active: true,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#d00111',
      primaryDarkColor: '#a30d19',
      headingTextColor: '#FFFFFF',
    },
    {
      id: '5b92dc3e-ff64-4c29-a296-a555dfac739c',
      name: "ICC Women's Cricket World Cup New Zealand 2022",
      enviro: 'PROD',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#ea0038',
      primaryDarkColor: '#c5002f',
      headingTextColor: '#FFFFFF',
    },
    {
      id: 'cfdc425a-cca0-44fc-aaa3-0406fc923b2b',
      name: "The Men's 6ixty",
      enviro: 'PROD',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#040130',
      primaryDarkColor: '#010007',
      headingTextColor: '#FFFFFF',
    },
    {
      id: 'b52df1ec-d11d-44a3-b17d-dc7af033d3bf',
      name: "The Women's 6ixty",
      enviro: 'PROD',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#aef801',
      primaryDarkColor: '#95d107',
      headingTextColor: '#040130',
    },
    {
      id: '99167b3f-02a5-48ac-b15f-041b0fa72dd4',
      name: "Men's CPL T20",
      enviro: 'PROD',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#1b1d41',
      primaryDarkColor: '#0b0c24',
      headingTextColor: '#FFFFFF',
    },
    {
      id: 'ef289343-d88c-4890-8713-e484849b7281',
      name: "Women's CPL T20",
      enviro: 'PROD',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#d00111',
      primaryDarkColor: '#a30d19',
      headingTextColor: '#FFFFFF',
    },
    {
      id: 'cea0b2e7-985c-47d7-8539-b3c53882b761',
      name: "ICC Men's T20 World Cup Australia 2022",
      enviro: 'PROD',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#10044a',
      primaryDarkColor: '#070221',
      headingTextColor: '#FFFFFF',
    },
    {
      id: 'd7b256d7-7bd5-468b-a747-47fccac1abac',
      name: "ICC Women's T20 World Cup South Africa 2023",
      enviro: 'PROD',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#B4FF0C',
      primaryDarkColor: '#90CC0A',
      headingTextColor: '#10044a',
    },
    {
      id: '33b84fd1-8dcf-417f-8d9c-843ca15b0e29',
      name: "ICC Men's Cricket World Cup Qualifier Zimbabwe 2023",
      enviro: 'PROD',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#302077',
      primaryDarkColor: '#2e1c63',
      headingTextColor: '#FFFFFF',
    },
    {
      id: '5f35d2f6-54cd-4038-90f4-d47afa995968',
      name: "Men's CPL T20 2023",
      enviro: 'PROD',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#1b1d41',
      primaryDarkColor: '#0b0c24',
      headingTextColor: '#FFFFFF',
    },
    {
      id: '9f6d9181-d4b3-44c1-9688-528471d27cc3',
      name: "Women's CPL T20 2023",
      enviro: 'PROD',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#d00111',
      primaryDarkColor: '#a30d19',
      headingTextColor: '#FFFFFF',
    },
    {
      id: '27ac40ed-e394-4aef-a557-ecebca4f0c95',
      name: "ICC Men's Cricket World Cup India 2023",
      enviro: 'PROD',
      active: false,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#320073',
      primaryDarkColor: '#1e0143',
      headingTextColor: '#FFFFFF',
    },
    {
      id: '228512a6-551c-4e37-a7b6-a307968ae582',
      name: "ICC Under-19 Men's Cricket World Cup 2024",
      enviro: 'PROD',
      active: true,
      config: {
        winPt: '2',
        tiePt: '1',
        nrPt: '1',
      },
      primaryColor: '#320073',
      primaryDarkColor: '#1e0143',
      headingTextColor: '#FFFFFF',
    },
  ],
}

export default ourTheme
